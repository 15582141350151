<template>
  <div id="app">
    <a-config-provider v-if="!loading" :locale="locale">
      <router-view />
    </a-config-provider>
  </div>
</template>

<script>
import { getLogoConfigs } from "@/api/system";

export default {
  data() {
    return {
      loading: true,
      logoItem: {},
    };
  },
  computed: {
    locale() {
      return this.$i18n.getLocaleMessage(this.$i18n.locale).antLocale;
    },
  },
  methods: {
    initData() {
      const teamNumber = window.location.hostname.split(".")[0];
      getLogoConfigs({ number: teamNumber }).then((data) => {
        this.loading = false;
        this.logoItem = data;

        this.$store.commit("setLogoConfig", data);

        if (this.logoItem.logo_title) {
          document.title = `${this.logoItem.logo_title}WMS - ` + this.$t("仓库管理系统");
        } else {
          document.title = this.$t("盒木WMS - 仓库管理系统");
        }

        if (this.logoItem.logo_image) {
          const link = document.createElement("link");
          link.type = "image/x-icon";
          link.rel = "icon";
          link.href = `/media/${this.logoItem.logo_image_item.file.split("/").pop()}`;
          document.getElementsByTagName("head")[0].appendChild(link);
        }
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style>
html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
.panel .ant-table {
  min-height: 220px !important;
}
</style>
