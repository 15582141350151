import VueClipboard from "vue-clipboard2";
import functions from "@/utils/functions";
import "ant-design-vue/dist/antd.less";
import Antd from "ant-design-vue/es";
import VueRouter from "vue-router";
import Print from "vue-print-nb";
import router from "./router";
import Viser from "viser-vue";
import i18n from "./locales";
import store from "./store";
import App from "./App.vue";
import Vue from "vue";
import Storage from "vue-ls";
import VueFullscreen from "vue-fullscreen";

const storageOptions = {
  namespace: "himool-WMS",
  name: "ls",
  storage: "local",
};

Vue.config.productionTip = false;
Vue.use(Storage, storageOptions);
Vue.prototype.$functions = functions;
Vue.use(VueClipboard);
Vue.use(VueRouter);
Vue.use(VueFullscreen);
Vue.use(Viser);
Vue.use(Print);
Vue.use(Antd);

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
