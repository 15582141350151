import { T } from "@/locales";
export default {
  path: "/stock_check",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/stock_check/stock_check_order_create",
  children: [
    {
      path: "stock_check_order_create",
      meta: { title: T("盘点任务新增"), permission: "stock_check_order" },
      component: () => import("@/views/stockCheck/stockCheckOrderCreate/index"),
    },
    {
      path: "stock_check_order_detail",
      meta: { title: T("盘点任务详情"), permission: "stock_check_order" },
      component: () => import("@/views/stockCheck/stockCheckOrderDetail/index"),
    },
    {
      path: "stock_check_task",
      meta: { title: T("盘点单"), permission: "stock_check_order" },
      component: () => import("@/views/stockCheck/stockCheckTask/index"),
    },
    {
      path: "stock_check_record_create",
      meta: { title: T("盘点记录新增"), permission: "stock_check_order" },
      component: () => import("@/views/stockCheck/stockCheckRecordCreate/index"),
    },
    {
      path: "stock_check_record_detail",
      meta: { title: T("盘点记录详情"), permission: "stock_check_order" },
      component: () => import("@/views/stockCheck/stockCheckRecordDetail/index"),
    },
    {
      path: "profit_loss_order_list",
      meta: { title: T("盘点损益单"), permission: "profit_loss_order" },
      component: () => import("@/views/stockCheck/profitLossOrderList/index"),
    },
    {
      path: "profit_loss_order_detail",
      meta: { title: T("损益单详情"), permission: "profit_loss_order" },
      component: () => import("@/views/stockCheck/profitLossOrderDetail/index"),
    },
  ],
};
