export default {
  state: () => ({
    username: "",
    isManager: false,
    permissions: [],
    config: {},
    barConfig: {},
    currentWarehouse: undefined,
    expiryTime: "",
    enableQualityInspection: false,
    enableCharge: false,
    homeConfig: [],
    defaultWarehouse: null,
    defaultClient: null,
    stockInOrderPrintConfig: {},
    shelveOrderPrintConfig: {},
    stockOutOrderPrintConfig: {},
    pickingOrderPrintConfig: {},
  }),
  mutations: {
    setUser(state, item) {
      state.username = item.username;
      state.isManager = item.is_manager;
      state.permissions = item.permissions;
      state.expiryTime = item.expiry_time;
      state.enableQualityInspection = item.enable_quality_inspection;
      state.enableCharge = item.enable_charge;
      item.home_config.sort((a, b) => a.sequential - b.sequential);
      state.homeConfig = item.home_config;
      state.defaultWarehouse = item.default_warehouse;
      state.defaultClient = item.default_client;
      state.stockInOrderPrintConfig = item.stock_in_order_print_config;
      state.shelveOrderPrintConfig = item.shelve_order_print_config;
      state.stockOutOrderPrintConfig = item.stock_out_order_print_config;
      state.pickingOrderPrintConfig = item.picking_order_print_config;
      state.stockCheckOrderPrintConfig = item.stock_check_order_print_config;
      state.barConfig = {
        stockInLabelLength: item.stock_in_label_length,
        stockInLabelWidth: item.stock_in_label_width,
        locationLabelLength: item.location_label_length,
        locationLabelWidth: item.location_label_width,
        materialLabelLength: item.material_label_length,
        materialLabelWidth: item.material_label_width,
        packingLabelWidth: item.packing_label_width,
        packingLabelLength: item.packing_label_length,
        palletLabelWidth: item.pallet_label_width,
        palletLabelLength: item.pallet_label_length,
      };
      if (item.configs) {
        for (let config of item.configs) {
          state.config[config.type] = config.strategy;
        }
      }
    },
    setWarehouse(state, value) {
      state.currentWarehouse = value;
    },
    setBarConfig(state, value) {
      state.barConfig = {
        stockInLabelLength: value.stock_in_label_length,
        stockInLabelWidth: value.stock_in_label_width,
        locationLabelLength: value.location_label_length,
        locationLabelWidth: value.location_label_width,
        materialLabelLength: value.material_label_length,
        materialLabelWidth: value.material_label_width,
        packinLlabelWidth: value.packing_label_width,
        packingLabelLength: value.packing_label_length,
        palletLabelWidth: value.pallet_label_width,
        palletLabelLength: value.pallet_label_length,
      };
    },
  },
};
