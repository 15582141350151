import { T } from "@/locales";
export default {
  path: '/',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'warehouse_layout',
      meta: { title: T('库位平面图'), permission: "floor_plan" },
      component: () => import('@/views/warehouseLayout'),
    },
  ],
}