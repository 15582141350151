import { T } from "@/locales";
export default {
  path: "/kanban",
  component: () => import("@/layouts/KanbanLayout"),
  children: [
    {
      path: "warehouse_kanban",
      meta: { title: T("仓库看板"), permission: "warehouse_kanban" },
      component: () => import("@/views/warehouseKanban"),
    },
    // {
    //   path: "receipt_kanban",
    //   meta: { title: T("入库看板") },
    //   component: () => import("@/views/receiptKanban"),
    // },
    // {
    //   path: "picking_kanban",
    //   meta: { title: T("拣货看板") },
    //   component: () => import("@/views/pickingKanban"),
    // },
  ],
};
