export default {
  path: '/user',
  component: () => import('@/layouts/UserLayout'),
  children: [
    {
      path: 'login',
      component: () => import('@/views/login/Login'),
    },
    {
      path: 'register',
      component: () => import('@/views/register/index'),
    },
    {
      path: 'set_password',
      component: () => import('@/views/setPassword/SetPassword'),
    },
  ],
}