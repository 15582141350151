import { T } from "@/locales";
export default {
  path: '/',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'stock_in_report',
      meta: { title: T('入库报表'), permission: "stock_in_report" },
      component: () => import('@/views/stockInReport'),
    },
    {
      path: 'stock_out_report',
      meta: { title: T('出库报表'), permission: "stock_out_report" },
      component: () => import('@/views/stockOutReport'),
    },
    {
      path: 'delivery_record_report',
      meta: { title: T('复核报表'), permission: "delivery_record_report" },
      component: () => import('@/views/deliveryRecordReport'),
    },
  ],
}