import { T } from "@/locales";
export default {
  path: "/stock_out",
  name: "stock_out",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/stock_out/delivery_order_create",
  children: [
    {
      path: "delivery_order_list",
      meta: { title: T('出库通知单'), permission: "delivery_order" },
      component: () => import("@/views/stockOut/deliveryOrderList/index"),
    },
    {
      path: "delivery_order_create",
      meta: { title: T('出库通知单新增'), permission: "delivery_order" },
      component: () => import("@/views/stockOut/deliveryOrderCreate/index"),
    },
    {
      path: "delivery_order_recreate",
      meta: { title: T('出库通知单新增'), permission: "delivery_order" },
      component: () => import("@/views/stockOut/deliveryOrderRecreate/index"),
    },
    {
      path: "picking_task",
      meta: { title: T('拣货任务'), permission: "picking_record" },
      component: () => import("@/views/stockOut/pickingTask/index"),
    },
    {
      path: "picking_task_detail",
      meta: { title: T('拣货任务详情'), permission: "picking_record" },
      component: () => import("@/views/stockOut/pickingTaskDetail/index"),
    },
    {
      path: "picking_record_detail",
      meta: { title: T('拣货记录详情'), permission: "picking_record" },
      component: () => import("@/views/stockOut/pickingRecordDetail/index"),
    },
    {
      path: "picking_record_create",
      meta: { title: T('拣货记录新增'), permission: "picking_record" },
      component: () => import("@/views/stockOut/pickingRecordCreate/index"),
    },
    {
      path: "delivery_task",
      meta: { title: T('复核任务'), permission: "delivery_record" },
      component: () => import("@/views/stockOut/deliveryTask/index"),
    },
    {
      path: "delivery_record_create",
      meta: { title: T('序列号复核'), permission: "delivery_record" },
      component: () => import("@/views/stockOut/deliveryRecordCreate/index"),
    },
    {
      path: "delivery_task_detail",
      meta: { title: T('复核任务详情'), permission: "delivery_record" },
      component: () => import("@/views/stockOut/deliveryTaskDetail/index"),
    },
    {
      path: "delivery_record_detail",
      meta: { title: T('复核记录详情'), permission: "delivery_record" },
      component: () => import("@/views/stockOut/deliveryRecordDetail/index"),
    },
    {
      path: "quick_delivery_record",
      meta: { title: T('复核'), permission: "delivery_record" },
      component: () => import("@/views/stockOut/quickDeliveryRecord/index"),
    },
    {
      path: "quick_picking_record",
      meta: { title: T('拣货出库'), permission: "picking_record" },
      component: () => import("@/views/stockOut/quickPickingRecord/index"),
    },
    {
      path: "pallet_picking",
      meta: { title: T('容器拣货'), permission: "picking_record" },
      component: () => import("@/views/stockOut/palletPicking/index"),
    },
    {
      path: "packing_delivery",
      meta: { title: T('复核包装'), permission: "delivery_record" },
      component: () => import("@/views/stockOut/packingDelivery/index"),
    },
  ],
};
