import { T } from "@/locales";

export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "function_config",
      component: () => import("@/views/functionConfig/index"),
    },
    {
      path: "order_prefix",
      meta: { title: T("单据字头"), permission: "manager" },
      component: () => import("@/views/orderPrefix/OrderPrefix"),
    },
    {
      path: "label_configs",
      meta: { title: T("标签配置"), permission: "manager" },
      component: () => import("@/views/labelConfigs/LabelConfigs"),
    },
    {
      path: "print_configs",
      meta: { title: T("打印配置"), permission: "manager" },
      component: () => import("@/views/printConfigs/index"),
    },
    {
      path: "system_configs",
      meta: { title: T("系统配置"), permission: "manager" },
      component: () => import("@/views/systemConfigs/SystemConfigs"),
    },
    {
      path: "logo_configs",
      meta: { title: T("Logo配置"), permission: "manager" },
      component: () => import("@/views/logoConfigs/index"),
    },
    {
      path: "operation_log",
      name: "operation_log",
      meta: { title: T("操作日志"), permission: "manager" },
      component: () => import("@/views/operationLog/index"),
    },
    {
      path: "login_log",
      name: "login_log",
      meta: { title: T("登录日志"), permission: "manager" },
      component: () => import("@/views/loginLog/index"),
    },
    {
      path: "update_log",
      meta: { title: T("更新日志") },
      component: () => import("@/views/updateLog/index"),
    },
    {
      path: "mobile_download",
      meta: { title: T("移动端下载") },
      component: () => import("@/views/mobileDownload/index"),
    },
  ],
};
