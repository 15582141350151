import { T } from "@/locales";
export default {
  path: "/stock_in",
  name: "stock_in",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/stock_in/receipt_order_list",
  children: [
    {
      path: "receipt_order_list",
      meta: { title: T('入库通知单'), permission: "receipt_order" },
      component: () => import("@/views/stockIn/receiptOrderList/index"),
    },
    {
      path: "receipt_order_create",
      meta: { title: T('入库通知单新增'), permission: "receipt_order" },
      component: () => import("@/views/stockIn/receiptOrderCreate/index"),
    },
    {
      path: "receipt_order_detail",
      meta: { title: T('入库通知单详情'), permission: "receipt_order" },
      component: () => import("@/views/stockIn/receiptOrderDetail/index"),
    },
    {
      path: "receipt_task",
      meta: { title: T('收货任务'), permission: "receipt_record" },
      component: () => import("@/views/stockIn/receiptTask/index"),
    },
    {
      path: "receipt_record_create",
      meta: { title: T('收货任务新增'), permission: "receipt_record" },
      component: () => import("@/views/stockIn/receiptRecordCreate/index"),
    },
    {
      path: "quick_receipt_record",
      meta: { title: T('收货入库'), permission: "receipt_record" },
      component: () => import("@/views/stockIn/quickReceiptRecord/index"),
    },
    {
      path: "receipt_record_detail",
      meta: { title: T('收货任务详情'), permission: "receipt_record" },
      component: () => import("@/views/stockIn/receiptRecordDetail/index"),
    },
    {
      path: "shelve_record_create",
      meta: { title: T('上架新增'), permission: "shelve_order" },
      component: () => import("@/views/stockIn/shelveRecordCreate/index"),
    },
    {
      path: "shelve_order_detail",
      meta: { title: T('上架单详情'), permission: "shelve_order" },
      component: () => import("@/views/stockIn/shelveOrderDetail/index"),
    },
    {
      path: "shelve_record_detail",
      meta: { title: T('上架任务详情'), permission: "shelve_order" },
      component: () => import("@/views/stockIn/shelveRecordDetail/index"),
    },
    {
      path: "shelve_task",
      meta: { title: T('上架任务'), permission: "shelve_order" },
      component: () => import("@/views/stockIn/shelveTask/index"),
    },
    {
      path: "pallet_receipt",
      meta: { title: T('容器收货'), permission: "receipt_record" },
      component: () => import("@/views/stockIn/palletReceipt/index"),
    },
    {
      path: "pallet_shelve",
      meta: { title: T('容器上架'), permission: "shelve_order" },
      component: () => import("@/views/stockIn/palletShelve/index"),
    },
  ],
};
