import { T } from "@/locales";
export default {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "stock_transfer/order_create",
      meta: { title: T("调拨单新增"), permission: "stock_transfer_order" },
      component: () => import("@/views/stockTransfer/orderCreate/index"),
    },
    {
      path: "stock_transfer/order_record",
      meta: { title: T("调拨单"), permission: "stock_transfer_order" },
      component: () => import("@/views/stockTransfer/orderRecord/index"),
    },
    {
      path: "stock_transfer/order_detail",
      meta: { title: T("调拨单详情"), permission: "stock_transfer_order" },
      component: () => import("@/views/stockTransfer/orderDetail/index"),
    },
    {
      path: "location_transfer_order_list",
      meta: { title: T("移库单"), permission: "location_transfer_order" },
      component: () => import("@/views/locationTransfer/locationTransferOrderList/index"),
    },
    {
      path: "location_transfer_order_create",
      meta: { title: T("移库单新增"), permission: "location_transfer_order" },
      component: () => import("@/views/locationTransfer/locationTransferOrderCreate/index"),
    },
    {
      path: "location_transfer_order_detail",
      meta: { title: T("移库单详情"), permission: "location_transfer_order" },
      component: () => import("@/views/locationTransfer/locationTransferOrderDetail/index"),
    },
    {
      path: "quality_adjust_order_list",
      meta: { title: T("品质调整单"), permission: "quality_adjust_order" },
      component: () => import("@/views/qualityAdjust/qualityAdjustOrderList/index"),
    },
    {
      path: "quality_adjust_order_create",
      meta: { title: T("品质调整单新增"), permission: "quality_adjust_order" },
      component: () => import("@/views/qualityAdjust/qualityAdjustOrderCreate/index"),
    },
    {
      path: "quality_adjust_order_detail",
      meta: { title: T("品质调整单详情 "), permission: "quality_adjust_order" },
      component: () => import("@/views/qualityAdjust/qualityAdjustOrderDetail/index"),
    },
  ],
};
