import { T } from "@/locales";
export default {
  path: "/",
  name: "inventory",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "inventory",
      component: () => import("@/views/inventory/Inventory"),
    },
    {
      path: "inventory_report",
      meta: { title: T("库存明细"), permission: "sub_batch" },
      component: () => import("@/views/inventoryReport/InventoryReport"),
    },
    {
      path: "batch_inventory_report",
      meta: { title: T("批次库存") },
      component: () => import("@/views/batchInventoryReport"),
    },
    {
      path: "material_inventory_report",
      meta: { title: T("库存报表"), permission: "inventory" },
      component: () => import("@/views/materialInventoryReport"),
    },
    {
      path: "location_flow",
      meta: { title: T("库位流水"), permission: "location_flow" },
      component: () => import("@/views/locationFlow"),
    },
    {
      path: "inventory_history",
      meta: { title: T("历史库存"), permission: "inventory_history" },
      component: () => import("@/views/inventoryHistory"),
    },
    {
      path: "inventory_flow",
      meta: { title: T("出入库流水") },
      component: () => import("@/views/inventoryFlow/index"),
    },
  ],
};
