import { T } from "@/locales";
export default {
  path: "/",
  name: "account",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "role",
      name: "role",
      meta: { title: T('角色管理'), permission: "manager" },
      component: () => import("@/views/role/Role"),
    },
    {
      path: "account",
      name: "account",
      meta: { title: T('员工账号'), permission: "manager" },
      component: () => import("@/views/account/Account"),
    },
  ],
};
