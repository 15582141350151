import { T } from "@/locales";
import VueRouter from "vue-router";
import user from "./user";
import account from "./account";
import manage from "./manage";
import system from "./system";
import stockIn from "./stockIn";
import plan from "./plan";
import stockOut from "./stockOut";
import stockCheck from "./stockCheck";
import stockTransfer from "./stockTransfer";
import inventory from "./inventory";
import statistic from "./statistic";
import kanban from "./kanban";
import charge from "./charge";

const index = {
  path: "/",
  component: () => import("@/layouts/BaseLayout"),
  redirect: "/home",
  children: [
    {
      path: "/home",
      name: "home",
      meta: { title: T("首页"), permission: "home" },
      component: () => import("@/views/home"),
    },
  ],
};

const routes = [
  index,
  user,
  account,
  manage,
  system,
  stockIn,
  plan,
  stockOut,
  stockCheck,
  stockTransfer,
  inventory,
  statistic,
  kanban,
  charge,
];

export default new VueRouter({ mode: "history", routes });
