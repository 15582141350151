import { T } from "@/locales";
export default {
  path: "/charge",
  component: () => import("@/layouts/BaseLayout"),
  children: [
    {
      path: "rule",
      meta: { title: T('计费规则'), permission: "charge_rule" },
      component: () => import("@/views/charge/rule"),
    },
    {
      path: "rule_detail",
      meta: { title: T('计费规则详情'), permission: "charge_rule" },
      component: () => import("@/views/charge/ruleDetail"),
    },
    {
      path: "expense",
      meta: { title: T('其他费用'), permission: "expense_record" },
      component: () => import("@/views/charge/expense"),
    },
    {
      path: "report",
      meta: { title: T('费用合计'), permission: "charge_detail" },
      component: () => import("@/views/charge/report"),
    },
    {
      path: "detail",
      meta: { title: T('费用合计详情'), permission: "charge_detail" },
      component: () => import("@/views/charge/detail"),
    },
  ],
};
