import request from "@/utils/request";

// Permission
export function permissionList(params) {
  return request({ url: `/permissions/`, method: "get", params });
}

// OrderPrefix
export function orderPrefixList(params) {
  return request({ url: `/order_prefixes/`, method: "get", params });
}

export function orderPrefixUpdate(data) {
  return request({ url: `/order_prefixes/${data.id}/`, method: "put", data });
}

// 标签配置信息
export function labelConfigsList(params) {
  return request({ url: `/label/configs/`, method: "get", params });
}

// 标签配置保存
export function labelConfigsSave(data) {
  return request({ url: `/label/set_configs/`, method: "post", data });
}

// 系统配置信息
export function systemConfigsList(params) {
  return request({ url: `/system/configs/`, method: "get", params });
}

export function systemConfigsSave(data) {
  return request({ url: `/system/set_configs/`, method: "post", data });
}

// 打印配置信息
export function printConfigsList(params) {
  return request({ url: `/print/configs/`, method: "get", params });
}

// 打印配置保存
export function printConfigsSave(data) {
  return request({ url: `/print/set_configs/`, method: "post", data });
}

// Logo配置信息
export function logoImageUpload(data) {
  return request({
    url: "/logo_images/",
    method: "post",
    data,
  });
}

export function getLogoConfigs(params) {
  return request({ url: `/logo/get_configs/`, method: "get", params });
}

export function logoConfigsList(params) {
  return request({ url: `/logo/configs/`, method: "get", params });
}

export function logoConfigsSave(data) {
  return request({ url: `/logo/set_configs/`, method: "post", data });
}

// 操作日志
export function operationLogList(params) {
  return request({ url: `/operation_logs/`, method: "get", params });
}

export function operationLogExport(params) {
  return request({
    url: "/operation_logs/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}

// 登录日志
export function loginLogList(params) {
  return request({ url: `/login_logs/`, method: "get", params });
}

export function loginLogExport(params) {
  return request({
    url: "/login_logs/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}
